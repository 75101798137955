/*
 * This file should be for globally useful helper classes and properties. Use it to help with DRY.
 * For component specific styling, use CSS-in-JS/react techniques
 */

 #body {
  .flex { display: flex; }
  .no-wrap { flex-flow: nowrap; }

  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }

  .mba { margin-bottom: auto; }
  .mb0 { margin-bottom: 0; }
  .mb10 { margin-bottom: 10px; }
  .mb20 { margin-bottom: 20px; }
  .mb30 { margin-bottom: 30px; }
  .mb40 { margin-bottom: 40px; }
  .mb50 { margin-bottom: 50px; }

  .mla { margin-left: auto; }
  .ml0 { margin-left: 0; }
  .ml10 { margin-left: 10px; }
  .ml20 { margin-left: 20px; }
  .ml30 { margin-left: 30px; }
  .ml40 { margin-left: 40px; }
  .ml50 { margin-left: 50px; }

  .mra { margin-right: auto; }
  .mr0 { margin-right: 0; }
  .mr10 { margin-right: 10px; }
  .mr20 { margin-right: 20px; }
  .mr30 { margin-right: 30px; }
  .mr40 { margin-right: 40px; }
  .mr50 { margin-right: 50px; }

  .mta { margin-top: auto; }
  .mt0 { margin-top: 0; }
  .mt10 { margin-top: 10px; }
  .mt20 { margin-top: 20px; }
  .mt30 { margin-top: 30px; }
  .mt40 { margin-top: 40px; }
  .mt50 { margin-top: 50px; }

  .p0 { padding: 0; }
  .p10 { padding: 10px; }
  .p20 { padding: 20px; }
  .p30 { padding: 30px; }
  .p40 { padding: 40px; }
  .p50 { padding: 50px; }

  .pb0 { padding-bottom: 0; }
  .pb10 { padding-bottom: 10px; }
  .pb20 { padding-bottom: 20px; }
  .pb30 { padding-bottom: 30px; }
  .pb40 { padding-bottom: 40px; }
  .pb50 { padding-bottom: 50px; }

  .pl0 { padding-left: 0; }
  .pl10 { padding-left: 10px; }
  .pl20 { padding-left: 20px; }
  .pl30 { padding-left: 30px; }
  .pl40 { padding-left: 40px; }
  .pl50 { padding-left: 50px; }

  .pr0 { padding-right: 0; }
  .pr10 { padding-right: 10px; }
  .pr20 { padding-right: 20px; }
  .pr30 { padding-right: 30px; }
  .pr40 { padding-right: 40px; }
  .pr50 { padding-right: 50px; }

  .pt0 { padding-top: 0; }
  .pt10 { padding-top: 10px; }
  .pt20 { padding-top: 20px; }
  .pt30 { padding-top: 30px; }
  .pt40 { padding-top: 40px; }
  .pt50 { padding-top: 50px; }

  .h100 { height: 100%; }
  .h100vh { height: 100vh; }
  .mh100vh { min-height: 100vh; }
  .w100 { width: 100%; }

  .break-all { word-break: break-all; }
  .break-word { word-break: break-word; }

  .borderless { border: none; }

  .pos-ab { position: absolute; }
  .pos-rel { position: relative; }
  .pos-sticky { position: sticky; }

  .ov-h { overflow: hidden; }
  .ov-s { overflow: scroll; }
  .ovx-h { overflow-x: hidden; }
  .ovx-s { overflow-x: scroll; }
  .ovy-h { overflow-y: hidden; }
  .ovy-s { overflow-y: scroll; }

  .hide-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .hide-desktop {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .sr-only-focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
  }
}
