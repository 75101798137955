#body {
  .black { color: black; }
  .white { color: white; }

  .bg-white { background: white; }
  .ant-menu-vertical .ant-menu-item-selected {
    color: white;
  }
}

