#body {
  min-width: 320px;

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-select-item-option {
    border-bottom: 1px solid @primary-color;
  }

  .show-options {
    .ant-select-item-option-content {
      overflow: inherit;
      white-space: inherit;
    }
  }

  .ant-row {
    font-size: 16px;
  }

  .ant-btn-primary {
    box-shadow: unset;
  }

  .ant-dropdown-button>.anticon.anticon-down,
  .ant-dropdown-link>.anticon.anticon-down,
  .ant-dropdown-trigger>.anticon.anticon-down {
    font-size: 10px;
    vertical-align: initial;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(0 122 152/20%);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #c3d8d9;
  }

  .ant-input-focused, .ant-input:focus {
    border-color: #1b8fa6;
    box-shadow: 0 0 0 2px rgb(0 122 152 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
}
