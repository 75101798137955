@import (css) url("//hello.myfonts.net/count/3eeadf");
@import (css) url("//hello.myfonts.net/count/3eebd3");

@font-face {
  font-display: swap;
  font-family: "ProximaNova-Regular";
  src: url('./webFonts/ProximaNovaRegular/font.eot');
  src:
    url('./webFonts/ProximaNovaRegular/font.eot?#iefix') format('embedded-opentype'),
    url('./webFonts/ProximaNovaRegular/font.woff2') format('woff2'),
    url('./webFonts/ProximaNovaRegular/font.woff') format('woff'),
    url('./webFonts/ProximaNovaRegular/font.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "ProximaNova-Medium";
  src: url('./webFonts/ProximaNovaMedium/font.eot');
  src:
    url('./webFonts/ProximaNovaMedium/font.eot?#iefix') format('embedded-opentype'),
    url('./webFonts/ProximaNovaMedium/font.woff2') format('woff2'),
    url('./webFonts/ProximaNovaMedium/font.woff') format('woff'),
    url('./webFonts/ProximaNovaMedium/font.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "ProximaNova-Bold";
  src: url('./webFonts/ProximaNovaBold/ProximaNova-Bold.eot');
  src:
    url('./webFonts/ProximaNovaBold/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('./webFonts/ProximaNovaBold/ProximaNova-Bold.woff2') format('woff2'),
    url('./webFonts/ProximaNovaBold/ProximaNova-Bold.woff') format('woff'),
    url('./webFonts/ProximaNovaBold//ProximaNova-Bold.ttf') format('truetype');
}
